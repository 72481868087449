import {track} from "@otto-ec/nav_star-track/src/js/star-track";

export function activateBackButton() {
    let button = (document.getElementById('nav_breadcrumb-headline-refer-back') ||
                  document.getElementById('nav_breadcrumb-list-refer-back'));

    if(!button) {
        return; 
    }

    button.classList.add('nav_grimm-refer-back--visible');
    if (!(button.dataset.grimm_listens === 'true')) {
        button.addEventListener('click', e => {
            track(e.target, 'user-action/click-back-button');
            history.back();
        });
        button.dataset.grimm_listens = 'true';
    }
}
