import {activateBackButton} from "./referBackButton";
import {isBackNavigationAllowed, isBreakpointS, isDebugMode, isMobile} from "./helper";
import {track} from "@otto-ec/nav_star-track/src/js/star-track";

function init() {
    if (isBreakpointS()) {
        if (isDebugMode()) {
            console.debug("nav.grimm: Initializing the breadcrumb.");
        }
        let storage = null;
        try {
            storage = new window.o_global.Storage(window.sessionStorage);
        } catch (e) {
            console.debug("no session storage available");
        }
        const entryPointKey = "nav-grimm-entry-point";

        if (isBackNavigationAllowed(storage, entryPointKey)) {
            activateBackButton();
        }
    }
    if (isMobile()) {
        const clickableBreadcrumbTitle = document.getElementsByClassName('nav_grimm-breadcrumb-headline__title--clickable')[0];

        if (clickableBreadcrumbTitle) {
            clickableBreadcrumbTitle.addEventListener('click', e => {
                o_global.eventQBus.emit('squirrel.search.show', {"breadcrumb": true});
                track(e.target, 'user-action/click-breadcrumb-headline');
            });
        }
    }
}

window.o_global.eventQBus.on('ftnav.breadcrumb.register', init);
window.o_global.eventQBus.on('ftfind.breadcrumb.loaded', init);
window.o_global.eventLoader.onReady(5, init);
