/**
 *
 */
export function isBreakpointS() {
    const currentBreakpoint = o_global.breakpoint.getCurrentBreakpoint();
    return currentBreakpoint === "s";
}

export function isMobile() {
    const currentBreakpoint = o_global.breakpoint.getCurrentBreakpoint();
    return currentBreakpoint === "s" || currentBreakpoint === "m";
}

/**
 *
 *
 */
export function isInvalidReferrer() {
    let referrer = document.referrer;
    return !referrer.includes(window.location.hostname);
}

/**
 *
 *
 *
 */
export function storeHrefAsEntryPoint(storage, key) {
    try {
        if (!!storage) {
            storage.setItem(key, location.href);
        }
    } catch(e) {
    }

}

/**
 *
 *
 *
 *
 *
 */
export function isEntryPoint(storage, key) {
    try {
        let value = !!storage ? storage.getItem(key) : null;
        return !!value && value === location.href;
    } catch(e) {
        return false;
    }
}

/**
 *
 *
 *
 *
 */
export function isBackNavigationAllowed(storage, key) {
    /**
 *
 *
 */

    if (isInvalidReferrer() || history.length <= 1) {
        storeHrefAsEntryPoint(storage, key);
        return false;
    }

    /**
 *
 *
 *
 *
 *
 *
 *
 */
    if (isEntryPoint(storage, key)) {
        return false;
    }

    return true;
}

export function isDebugMode() {
    if (window.o_global &&
        window.o_global.debug &&
        window.o_global.debug.status &&
        typeof window.o_global.debug.status === 'function') {
        return window.o_global.debug.status().activated;
    } else {
        return false;
    }
}
